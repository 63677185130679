<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
            class="modal-extended"
            :show.sync="$store.state.contenedores.modalElementos"
            :title="tituloModal"
            :close-on-backdrop="false"
           
        >
            <CRow>
                 <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.group')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :value="formElement.TpCargoId"
                        :addInputClasses="{ 'is-invalid': $v.formElement.TpCargoId.$error }"
                        @blur="$v.formElement.TpCargoId.$touch()"
                        @input="formElement.TpCargoId = $event.target.value"
                        :options="optionListCargo"
                        :invalid-feedback="errorMessage($v.formElement.TpCargoId)"
                        :is-valid="hasError($v.formElement.TpCargoId)"
                    >

                    </CSelect>
                </CCol>
                 <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.view')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :value="formElement.TpCargoViewId"
                        :addInputClasses="{ 'is-invalid': $v.formElement.TpCargoViewId.$error }"
                        @blur="$v.formElement.TpCargoViewId.$touch()"
                        @input="formElement.TpCargoViewId = $event.target.value"
                        :options="optionListView"
                        :invalid-feedback="errorMessage($v.formElement.TpCargoViewId)"
                        :is-valid="hasError($v.formElement.TpCargoViewId)"
                    >
                    </CSelect>
                </CCol>
                <CCol sm="12" lg="12" >
                    <CInput
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :placeholder="$t('label.elementName')"
                        v-model="$v.formElement.TpCargoElementName.$model"
                        @blur="$v.formElement.TpCargoElementName.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formElement.TpCargoElementName.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formElement.TpCargoElementName)"
                        :is-valid="hasError($v.formElement.TpCargoElementName)"
                    >
                    </CInput>
                </CCol>
                 <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :is-valid="formElement.FgActCargoElement" 
                        v-if="!idIndentification"
                        v-model="formElement.FgActCargoElement"
                        :value.sync="formElement.FgActCargoElement"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading || loading">
                        <span  class="spinner-border spinner-border-sm" role="Element" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                    <div v-if="!apiStateFormLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
                
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormElement } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    //data
    function data() {
        return {
            formElement:{
                TpCargoId:'',
                TpCargoViewId:'',
                TpCargoElementId:0,
                TpCargoElementName:'',
                FgActCargoElement:true,
            },
            originalAct: '',
            tituloModal: this.$t('label.nuevo')+' '+ this.$t('label.part'),
            loading:false,
        }
    }
    //methods
    function cambio(e){
        this.formElement.FgActCargoElement = !this.formElement.FgActCargoElement;
    }
    function cerrarModal(){
        if(this.apiStateFormLoading || this.loading)
            return
        this.$store.state.contenedores.modalElementos = false;
    }
    function registerData(){
       
        try {
              this.$v.formElement.$touch();
          if (this.$v.formElement.$pending || this.$v.formElement.$error) {
                throw this.$t('label.errorsPleaseCheck');
             } 

                let { TpCargoElementId, TpCargoViewId, TpCargoId,TpCargoElementName,FgActCargoElement } = this.formElement;
                const { dispatch } = this.$store;
                if(this.originalAct !== '' && TpCargoElementId !== 0){
                    if(this.originalAct !== FgActCargoElement){
                        this.$swal.fire(
                                alertPropertiesHelpers(this,{
                                    text: `${this.$t('label.changeStatusQuestion')} ${TpCargoElementName}?`
                                })
                            ).then((result) => {
                            if (result.isConfirmed) {
                                dispatch('contenedores/dataElement', { TpCargoElementId, TpCargoViewId, TpCargoId,TpCargoElementName,FgActCargoElement });
                            }else {
                                //this.$swal(this.$t('label.operationCancelled'));
                            }
                        })
                    }else{
                        dispatch('contenedores/dataElement', { TpCargoElementId, TpCargoViewId, TpCargoId,TpCargoElementName,FgActCargoElement });
                    }
                }else{
                    dispatch('contenedores/dataElement', { TpCargoElementId, TpCargoViewId, TpCargoId,TpCargoElementName,FgActCargoElement });
                }

        } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    //computed
    function idIndentification(){
        return this.formElement.TpCargoElementId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function optionListCargo(){
        if(this.myDataGrupo.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataGrupo.map(function(e){
                if(e.FgActTpCargo){
                    chart.push({
                        value: e.TpCargoId, 
                        label: e.TpCargoName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListView(){
        if(this.myDataView.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            const cargoID = this.formElement.TpCargoId;
            if(cargoID === ''){
                this.formElement.TpCargoViewId = '';
                return chart;
            }else{
                this.myDataView.map(function(e){
                    if(e.FgActTpCargoView){
                        if(e.TpCargoId === cargoID){
                            chart.push({
                                value: e.TpCargoViewId, 
                                label: e.TpCargoViewName,
                            })
                        }
                    }
                })
                return chart;
            }
        }
    }

    function selectOptions() {
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }
    //watch
    function modalElement(newQuestion){
        if(newQuestion === false){
            this.formElement.TpCargoElementId=0;
            this.formElement.TpCargoElementName='';
            this.formElement.TpCargoViewId = '';
            this.formElement.TpCargoId = '';
            this.originalAct = '';
            this.tituloModal = this.$t('label.nuevo')+' '+ this.$t('label.part');
            this.formElement.FgActCargoElement=true;
            this.$store.state.contenedores.filtroCargo = '';
            this.$store.state.contenedores.filtroView = '';
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);
        }else{
            this.tituloModal = this.$t('label.nuevo')+' '+ this.$t('label.part');
            if(this.idState !== 0){
                const id = this.idState;
                this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'TpCargoViewElement-by-id',{ TpCargoElementId: id }, '' ).then(response => {
                    try {
                        if(response.status === 200){
                            const Information = response.data.data;
                            if(Information.length !== 0){
                                this.tituloModal = this.$t('label.edit')+' '+this.$t('label.part')+': '+Information[0].TpCargoElementName;
                                this.formElement.TpCargoElementId= Information[0].TpCargoElementId;
                                this.formElement.TpCargoElementName= Information[0].TpCargoElementName;
                                this.formElement.TpCargoViewId= Information[0].TpCargoViewId;
                                this.formElement.TpCargoId= Information[0].TpCargoId;
                                this.originalAct = Information[0].FgActTpCargoElement;
                                this.formElement.FgActCargoElement= Information[0].FgActTpCargoElement;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }
                            this.$v.$touch();
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalElement = false;
                        }
                    } catch (error) {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalElement = false;
                        this.$store.commit('contenedores/messageMutation', err);
                    }
                }).catch(err => {
                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                    this.$store.state.contenedores.modalElement = false;
                    this.$store.commit('contenedores/messageMutation', err);
                });
            }else{
                if(this.filtroCargo !== ''){
                    this.formElement.TpCargoId= this.filtroCargo;
                }
                if(this.filtroView !== ''){
                    this.formElement.TpCargoViewId= this.filtroView;
                }
            }
        }
    }

    export default {
        name: 'modal-element',
        data,
        validations(){ return FormElement() },
        methods:{
            registerData,
            cambio,
           // errorMessage,
            cerrarModal
        },
        mixins:[GeneralMixin],
        directives: UpperCase,
        computed:{
            idIndentification,
            optionListCargo,
            optionListView,
            apiStateFormLoading,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalElement: state=> state.contenedores.modalElementos,
                myDataGrupo: state => state.contenedores.myDataGrupo,
                myDataView: state => state.contenedores.myDataView,
                filtroView: state => state.contenedores.filtroView,
                filtroCargo: state => state.contenedores.filtroCargo,
            })
        },
        watch:{
            modalElement
        }
    }
</script>
